<template>
  <div class="ion-page">
    <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
    <ion-header>
      <ion-toolbar color="primary-contrast">
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <div class="ion-text-center">
          <ion-icon
            v-if="isOfflineReady"
            icon="md-radio-button-on"
            size="large"
            color="success"
          ></ion-icon>
          <ion-icon
            v-if="!isOfflineReady"
            icon="md-radio-button-off"
            size="large"
            color="primary"
          ></ion-icon>
          <img src="../assets/images/300x100_placeholder.png" />
          <ion-icon
            v-if="!isOfflineReady"
            icon="md-time"
            size="large"
            color="primary"
          ></ion-icon>
        </div>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-select
        class="selectVideo"
        aria-label="Videos"
        placeholder="Select video"
        :value="selectedVideo"
        @ionChange="selectedVideo = $event.target.value"
        :disabled="isPlaying"
      >
        <ion-select-option
          v-for="(video, index) in videos"
          :key="index"
          :value="video.code"
          >{{ video.name }}</ion-select-option
        >
      </ion-select>
      <!-- Inizio Schede Video -->
      <video
        class="displayVideo"
        v-show="1 == selectedVideo"
        controls=""
        controlslist="nodownload"
        disablepictureinpicture
        @play="disableSelect()"
        @pause="disableSelect()"
      >
        <source src="../assets/videos/1_video.mp4" type="video/mp4" />
      </video>
      <video
        class="displayVideo"
        v-show="2 == selectedVideo"
        controls=""
        controlslist="nodownload"
        disablepictureinpicture
        @play="disableSelect()"
        @pause="disableSelect()"
      >
        <source src="../assets/videos/2_video.mp4" type="video/mp4" />
      </video>
      <video
        class="displayVideo"
        v-show="3 == selectedVideo"
        controls=""
        controlslist="nodownload"
        disablepictureinpicture
        @play="disableSelect()"
        @pause="disableSelect()"
      >
        <source src="../assets/videos/3_video.mp4" type="video/mp4" />
      </video>
      <video
        class="displayVideo"
        v-show="4 == selectedVideo"
        controls=""
        controlslist="nodownload"
        disablepictureinpicture
        @play="disableSelect()"
        @pause="disableSelect()"
      >
        <source src="../assets/videos/4_video.mp4" type="video/mp4" />
      </video>

      <video
        class="displayVideo"
        v-show="5 == selectedVideo"
        controls=""
        controlslist="nodownload"
        disablepictureinpicture
        @play="disableSelect()"
        @pause="disableSelect()"
      >
        <source src="../assets/videos/5_video.mp4" type="video/mp4" />
      </video>
      <video
        class="displayVideo"
        v-show="6 == selectedVideo"
        controls=""
        controlslist="nodownload"
        disablepictureinpicture
        @play="disableSelect()"
        @pause="disableSelect()"
      >
        <source src="../assets/videos/6_video.mp4" type="video/mp4" />
      </video>
      <video
        class="displayVideo"
        v-show="7 == selectedVideo"
        controls=""
        controlslist="nodownload"
        disablepictureinpicture
        @play="disableSelect()"
        @pause="disableSelect()"
      >
        <source src="../assets/videos/7_video.mp4" type="video/mp4" />
      </video>
      <video
        class="displayVideo"
        v-show="8 == selectedVideo"
        controls=""
        controlslist="nodownload"
        disablepictureinpicture
        @play="disableSelect()"
        @pause="disableSelect()"
      >
        <source src="../assets/videos/8_video.mp4" type="video/mp4" />
      </video>

      <video
        class="displayVideo"
        v-show="9 == selectedVideo"
        controls=""
        controlslist="nodownload"
        disablepictureinpicture
        @play="disableSelect()"
        @pause="disableSelect()"
      >
        <source src="../assets/videos/9_video.mp4" type="video/mp4" />
      </video>
      <video
        class="displayVideo"
        v-show="10 == selectedVideo"
        controls=""
        controlslist="nodownload"
        disablepictureinpicture
        @play="disableSelect()"
        @pause="disableSelect()"
      >
        <source src="../assets/videos/10_video.mp4" type="video/mp4" />
      </video>
      <video
        class="displayVideo"
        v-show="11 == selectedVideo"
        controls=""
        controlslist="nodownload"
        disablepictureinpicture
        @play="disableSelect()"
        @pause="disableSelect()"
      >
        <source src="../assets/videos/11_video.mp4" type="video/mp4" />
      </video>
      <video
        class="displayVideo"
        v-show="12 == selectedVideo"
        controls=""
        controlslist="nodownload"
        disablepictureinpicture
        @play="disableSelect()"
        @pause="disableSelect()"
      >
        <source src="../assets/videos/12_video.mp4" type="video/mp4" />
      </video>

      <video
        class="displayVideo"
        v-show="13 == selectedVideo"
        controls=""
        controlslist="nodownload"
        disablepictureinpicture
        @play="disableSelect()"
        @pause="disableSelect()"
      >
        <source src="../assets/videos/13_video.mp4" type="video/mp4" />
      </video>
      <video
        class="displayVideo"
        v-show="14 == selectedVideo"
        controls=""
        controlslist="nodownload"
        disablepictureinpicture
        @play="disableSelect()"
        @pause="disableSelect()"
      >
        <source src="../assets/videos/14_video.mp4" type="video/mp4" />
      </video>
      <video
        class="displayVideo"
        v-show="15 == selectedVideo"
        controls=""
        controlslist="nodownload"
        disablepictureinpicture
        @play="disableSelect()"
        @pause="disableSelect()"
      >
        <source src="../assets/videos/15_video.mp4" type="video/mp4" />
      </video>
      <!-- Fine Schede Video -->
    </ion-content>

    <ion-footer class="ion-text-center">
      <img src="../assets/images/500x80_placeholder.png" />
    </ion-footer>
  </div>
</template>

<script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "HomePage",
  props: {
    msg: String,
  },
  data: function() {
    return {
      isLoading: false,
      fullPage: true,
      searchText: "",
      videos: [
        { name: "TRAILER FR", code: 1 },
        { name: "VIDEO 1 EN", code: 2 },
        { name: "3_video", code: 3 },
        { name: "4_video", code: 4 },
        { name: "5_video", code: 5 },
        { name: "6_video", code: 6 },
        { name: "7_video", code: 7 },
        { name: "8_video", code: 8 },
        { name: "9_video", code: 9 },
        { name: "10_video", code: 10 },
        { name: "11_video", code: 11 },
        { name: "12_video", code: 12 },
        { name: "13_video", code: 13 },
        { name: "14_video", code: 14 },
        { name: "15_video", code: 15 },
      ],
      selectedVideo: null,
      isPlaying: false,
      isOfflineReady: false,
    };
  },
  components: {
    Loading,
  },

  mounted() {
    document.addEventListener("cachedEvent", this.handleCustomEvent);
    document.addEventListener("readyEvent", this.handleCustomEvent);
  },
  beforeUnmount() {
    document.removeEventListener("cachedEvent", this.handleCustomEvent);
    document.removeEventListener("readyEvent", this.handleCustomEvent);
  },
  methods: {
    presentAlert(header,subtitle,message) {
      return this.$ionic.alertController
        .create({
          header: header,
          subHeader: subtitle,
          message: message,
          buttons: ["OK"],
        })
        .then((a) => a.present());
    },
    handleCustomEvent(event) {
      /* eslint-disable no-console */
      console.log("Evento ricevuto nel componente Home:", event);
      if (event.value) {
        this.isOfflineReady = true;
        this.presentAlert("messaggio","","Il dispositivo può essere utilizzato offline");
      }
    },
    disableSelect() {
      if (this.isPlaying) {
        this.isPlaying = false;
      } else {
        this.isPlaying = true;
      }
    },
    isDisabled() {
      if (this.isPlaying) return true;
      else return false;
    },
    selected(e, selected) {
      if (selected) {
        e.target.style.backgroundColor = "#DB3939";
        e.target.style.color = "#FFFFFF";
      } else {
        e.target.style.backgroundColor = "#FFFFFF";
        e.target.style.color = "#000000";
      }
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
section {
  margin-top: 2em;
  margin-left: 1em;
  margin-right: 1em;
}
.btn {
  margin-top: 20px;
}
.receiver-link-card {
  --background-activated: #db3939;
  color: #ffffff;
}
ion-card-content {
  color: #000000;
}
ion-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.displayVideo {
  width: 700px;
  margin-inline: 37%;
}
.selectVideo {
  margin: auto;
  width: 300px;
}
</style>
